<ng-container *transloco="let t; prefix: 'mainPage'">
  <div>
    <h3>{{ t('boilerplateTitle') }}</h3>
    <h3>{{ t('createdWithTitle') }}</h3>

    <p>{{ t('basicFunctionalities.title') }}:</p>
    <ul>
      <li>{{ t('basicFunctionalities.shell') }}</li>
      <li>{{ t('basicFunctionalities.sso') }}</li>
      <li>{{ t('basicFunctionalities.countrySelector') }}</li>
      <li>{{ t('basicFunctionalities.languageSelector') }}</li>
      <li>{{ t('basicFunctionalities.transloco') }}</li>
    </ul>
  </div>
</ng-container>
