import { enableProdMode, importProvidersFrom } from '@angular/core';
import { provideTransloco } from '@jsverse/transloco';

import { environment } from './environments/environment';
import { appInitializerProvider } from './app/core/app-initializer/app-initializer.service';
import { ENVIRONMENT } from './app/core/models/environment.token';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { TranslocoHttpLoader } from './app/transloco-loader';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, AppRoutingModule),
    appInitializerProvider,
    ...environment.providers,
    { provide: ENVIRONMENT, useValue: environment },
    provideHttpClient(withInterceptorsFromDi()),
    //transloco config
    provideTransloco({
      config: {
        availableLangs: ['en-US'],
        defaultLang: 'en-US',
        fallbackLang: 'en-US',
        missingHandler: {
          useFallbackTranslation: true,
        },
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,
    }),
  ],
}).catch(err => console.error(err));
